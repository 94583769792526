import { navigate } from "gatsby";
import {
  Box, ResponsiveContext
} from "grommet";
import React from "react";
import BlockUi from "react-block-ui";
import 'react-block-ui/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConnectHubrise from "../components/connect_hubrise";
import Layout from '../components/elements/layout';
import config from '../config/config.json';
import { authorizeHubrise, getLocationProfile, syncCatalogs } from "../services/service";
import { getCatalogToken, getOrderToken, setCatalogToken, setDisponibility, setLocation, setOrderToken } from "../services/storage";

const showMessage = (message, type) => {
  toast.error(message, type || 'info');
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount = () => {
    navigate("https://app-new.meor.fr/");
  }

  authorizeOrdersScope = () => {
    navigate(`${config.hubrise_manager}/oauth2/v1/authorize?redirect_uri=${config.baseAPI}/login?type=orders&client_id=${config.client_id_order}&scope=location[orders.write]`)
  }

  authorizeCatalogsScope = () => {
    navigate(`${config.hubrise_manager}/oauth2/v1/authorize?redirect_uri=${config.baseAPI}/login?type=catalogs&client_id=${config.client_id_catalog}&scope=location[all_catalogs.write]`)
  }


  render() {
    return (
      <BlockUi tag="div" blocking={this.state.loading}>
        <Layout style={{ justifyContent: "center", alignContent: "center" }}>
          <Box width="full" justify="center" align="center" alignSelf="center">
            <ResponsiveContext.Consumer>
              {size =>
                <Box width="full">
                  <ConnectHubrise
                    authorizeOrdersScope={() => this.authorizeOrdersScope()}
                    authorizeCatalogsScope={() => this.authorizeCatalogsScope()}
                  />
                </Box>
              }
            </ResponsiveContext.Consumer>

            <Box style={{ height: 200 }} />
          </Box>
          <ToastContainer position="top-right"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Layout>
      </BlockUi>
    )
  }
}

export default Login;
