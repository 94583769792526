import {
    Box, Card, Text
} from "grommet";
import React from "react";
import { getCatalogToken, getOrderToken } from "../../services/storage";
import { COLOR } from "../../services/utils";

const bgColor = '#e6f3fe';
const frontColor = '#033076';

const ConnectHubrise = ({ authorizeOrdersScope, authorizeCatalogsScope }) => {

    return (
        <Box width={'full'} height='100vh' align='center' justify="center">
            <Box align="center" gap="medium">
                <Card height={600} background={COLOR.light_blue} pad='xlarge' round={{ size: 'medium' }} border={{ color: COLOR.dark_blue, size: 'medium' }}>
                    <Text color={COLOR.dark_blue} style={{ fontSize: 120 }}>MEOR</Text>
                </Card>
                <br />

                <Card height={400} background={getOrderToken() ? COLOR.background_disabled : COLOR.dark_blue} pad='large'
                    round={{ size: 'small' }}
                    border={{ color: getOrderToken() ? COLOR.background_disabled : COLOR.light_blue, size: 'small' }}
                    onClick={getOrderToken() ? null : authorizeOrdersScope}
                >
                    <Text color={COLOR.light_blue} style={{ fontSize: 80 }}>Synchroniser commandes</Text>
                </Card>

                <Card height={400} background={getCatalogToken() ? COLOR.background_disabled : COLOR.dark_blue} pad='large'
                    round={{ size: 'small' }}
                    border={{ color: getCatalogToken() ? COLOR.background_disabled : COLOR.light_blue, size: 'small' }}
                    onClick={getCatalogToken() ? null : authorizeCatalogsScope}
                >
                    <Text color={COLOR.light_blue} style={{ fontSize: 80 }}>Synchroniser catalogues</Text>
                </Card>
            </Box>
        </Box>
    )
}

export default ConnectHubrise;